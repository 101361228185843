<template>
    <div class="modal fade show">
        <div class='modal__backdrop'></div>
        <div class="modal-dialog zindex-2" role="document">
            <div class="modal-content">
                <div class="modal-header bg-light">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{ $t('commons.add_new_file') }}
                    </h5>
                    <button type="button" class="close" @click="closeModal()">
                        <i aria-hidden="true" class="ki ki-close font-size-h4"></i>
                    </button>
                </div>
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                    <form>
                        <div class="modal-body min-h-350px">
                            <div class="form-group">
                                <label class="col-form-label">{{ $t('commons.type') }}</label>
                                <validation-provider rules="required" :name="$t('commons.type')" v-slot="{ classes, errors }">
                                    <multiselect v-model="selectedFileType" :options="fileTypes" :searchable="false"
                                        :allow-empty="false" open-direction="bottom" deselect-label=''
                                        select-label='' selected-label='' track-by='name' label='name'
                                        :placeholder="$t('commons.choosen_status')" :class="classes">
                                        <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                                        <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                                        <template slot='noOptions'>{{ 'List is empty' }}</template>
                                    </multiselect>
                                    <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                            </div>
                            <div class="form-group">
                                <label class="col-form-label col-12 pl-0">{{ $t('commons.file') }} ({{ $t("commons.accepts_only_pdf_images") }})</label>
                                <validation-provider class="col-12 pl-0" :rules="'required|mimes:application/pdf,image/*'" :name="$t('commons.file')"
                                    v-slot="{ classes, errors, validate }">
                                    <span class="btn btn-outline-primary col-6 round btn-file" :class="classes">
                                        {{ $t('commons.browseFile') }}
                                        <input id="document_file" ref="document_file" name="document_file"
                                            @change="validate" type="file" class="custom-input" accept="image/*,.pdf"
                                            :class="classes" />
                                    </span>
                                    <span class="error__message">{{ errors[0] }}</span>
                                    <span class="fileName" v-if="$refs.document_file && $refs.document_file?.files[0]?.name">
                                        {{ $refs.document_file.files[0].name }}
                                    </span>
                                </validation-provider>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-outline-secondary font-weight-bold text-uppercase" @click="closeModal" data-dismiss="modal">
                                {{ $t('commons.cancel') }}
                            </button>
                            <button type="button" class="btn btn-success font-weight-bold text-uppercase" @click="handleSubmit(onSubmit)" :disabled="isSubmitting">
                                <span
                                    v-if="isSubmitting"
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                {{ $t('commons.submit') }}
                            </button>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>
  
<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import i18n from '@/config/_i18n';
import { getErrorServiceMsg } from '@/utils';

const fileTypes = [
  { value: 'identity_front', name: i18n.t("c_management.identity_front") },
  { value: 'identity_back', name: i18n.t("c_management.identity_back") },
  { value: 'signed_paper', name: i18n.t("c_management.signed_paper") },
  { value: 'company_establishment', name: i18n.t("c_management.company_establishment") },
  { value: 'tax_plate', name: i18n.t("c_management.tax_plate") },
  { value: 'passport_partner', name: i18n.t("c_management.passport_partner") },
  { value: 'signature_circulars', name: i18n.t("c_management.signature_circulars") },

];

export default {
    name: "CustomerAddFileModal",
    props: {
        userId: String
    },
    components: {
        Multiselect,
    },
    data() {
        return {
            selectedFileType: fileTypes[0],
            fileTypes,
            isSubmitting: false
        };
    },
    computed: {
    },
    methods: {
        closeModal() {
            this.$emit('onClose');
        },
        async onSubmit() {
            let formData = new FormData();
            let docFile = this.$refs.document_file.files[0];
            formData.append("file", docFile);
            formData.append("id", this.userId);
            formData.append("type", this.selectedFileType.value);
            this.isSubmitting = true;
            try {
                await this.$store.dispatch('userdetails/addNewUserAttachment', formData);
                this.isSubmitting = false;
                this.$toast.success(this.$t("service.operation_success"));
                this.$refs.form.reset();
                this.closeModal();
                this.$emit('onSuccess')
            } catch (error) {
                this.isSubmitting = false;
                this.$toast.error(getErrorServiceMsg(error?.response));
            }
        }
    },
    created() { },
};
</script>
<style scoped>
.show {
    display: block;
}

.modal__backdrop {
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}
</style>